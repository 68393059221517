.noResultLabel {
    font-size: 16px !important;
}

.container {
    padding: 35px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;

    h2 {
        font-size: 32px;
        text-align: center;
    }
}

.noResultResetText {
    text-decoration-line: underline;
    font-size: 16px
}

.btns {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 24px;
}

@media (max-width: 1100px) {
    .btns {
        flex-direction: column;
        gap: 16px;
    }
}

@media (max-width: 780px) {
    .container {
        padding: 40px 10px;

        h2 {
            font-size: 20px;
        }
    }
}
